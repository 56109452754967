import API from "../../services/api";

export default {
    state: {
        payments: [],
        paymentsF: [],
        payment: {},
    },

    mutations: {
        setPayments(state, payments) {
            state.payments = payments;
            state.paymentsF = payments;
        },
        setPaymentsF(state, payments) {
            state.paymentsF = payments;
        },
        setPayment(state, payment) {
            state.payment = payment;
        },
    },

    actions: {
        getPayments(ctx) {
            ctx.dispatch("getProfile");
            API.GET("payment/my").then((res) => {
                console.log(res.data);
                ctx.commit("setPayments", res.data.payments);
            });
        },

        fillPayment(ctx) {
            ctx.commit("setPaymentsF", ctx.state.payments);
        },

        findPayment(ctx, s) {
            if (!ctx.state.payments) {
                return null;
            }

            let list = ctx.state.payments;

            if (s.findme != "" && s.findme != undefined) {
                list = list.filter((item) => {
                    let event = null;
                    let order_id = null;
                    let name = null;
                    let secondname = null;
                    let email = null;
                    let phone = null;

                    event = item.event.name.toLowerCase().indexOf(s.findme.toLowerCase()) !== -1;
                    order_id = item.order_id.toLowerCase().indexOf(s.findme.toLowerCase()) !== -1;
                    name = item.profile.name.toLowerCase().indexOf(s.findme.toLowerCase()) !== -1;
                    secondname = item.profile.secondname.toLowerCase().indexOf(s.findme.toLowerCase()) !== -1;
                    email = item.profile.email.toLowerCase().indexOf(s.findme.toLowerCase()) !== -1;
                    phone = item.profile.phone.toLowerCase().indexOf(s.findme.toLowerCase()) !== -1;

                    if (event || order_id || name || secondname || email || phone) {
                        return true;
                    }
                    return false;
                });
            }

            if (s.status != "" && s.status != undefined) {
                list = list.filter((item) => parseInt(item.status) == parseInt(s.status));
            }

            if ((s.canceled != "" && s.canceled != undefined) || s.canceled === 0) {
                list = list.filter((item) => parseInt(item.canceled) == parseInt(s.canceled));
            }

            if (list.length != ctx.state.payments.length) {
                s.clear = true;
            }

            ctx.commit("setPaymentsF", list);
        },

        editPayment(ctx, payment) {
            ctx.commit("setPayment", payment);
            ctx.dispatch("setPopup", "editPayment");
        },

        cancelPayment(ctx, payment) {
            let formData = new FormData();
            formData.append("id", payment.id);
            API.POST("payment/cancel", formData).then((res) => {
                ctx.commit("setPayments", res.data.payments);
                ctx.commit("setSubs", res.data.subscribes);
            });
        },
    },

    getters: {
        payments(state) {
            return state.paymentsF;
        },
        payment(state) {
            return state.payment;
        },
    },
};
