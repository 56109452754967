<template>
    <div class="w-full">
        <div class="bg-white p-4">
            <div class="flex flex-col lg:flex-row gap-4 justify-between items-center">
                <h2 class="text-sm text-slate-500">
                    ПОДПИСКИ <sup>{{ subscribes.length }}</sup>
                </h2>

                <!-- <input
                    type="text"
                    class="h-8 w-1/3 px-3 py-2 text-sm bg-slate-100"
                    placeholder="Название или ФИО гостя, email, телефон"
                    v-model="s.findme"
                    @input="findSub(s)"
                /> -->

                <div class="flex gap-2">
                    <div
                        @click="filterSub([1])"
                        class="w-auto lg:w-20 text-yellow-400 border border-yellow-400 hover:bg-yellow-300 hover:text-white text-xs lg:text-sm text-center p-1 rounded cursor-pointer"
                    >
                        Бронь
                    </div>
                    <div
                        @click="filterSub([5])"
                        class="w-auto lg:w-20 text-green-400 border border-green-400 hover:bg-green-300 hover:text-white text-xs lg:text-sm text-center p-1 rounded cursor-pointer"
                    >
                        Активна
                    </div>
                    <div
                        @click="filterSub([7, 8, 9])"
                        class="w-auto lg:w-20 text-red-400 border border-red-400 hover:bg-red-300 hover:text-white text-xs lg:text-sm text-center p-1 rounded cursor-pointer"
                    >
                        Отменена
                    </div>

                    <div class="w-auto lg:w-20">
                        <div
                            v-if="s.clear"
                            @click="clear()"
                            class="w-auto lg:w-20 text-white bg-gray-400 hover:bg-gray-300 text-xs lg:text-sm text-center p-1 rounded cursor-pointer"
                        >
                            Сброс
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="bg-white p-4 mt-4">
            <div class="hidden lg:grid lg:grid-cols-7 mb-4 px-2 text-xs text-slate-400">
                <p class="col-span-2">Название</p>
                <p>Тип</p>
                <p class="text-right">Стоимость</p>
                <p class="text-right">Дата начала</p>
                <p class="text-right">Дата окончания</p>
                <p class="text-right">Статус</p>
            </div>
            <template v-for="item in subscribes" :key="item.id">
                <div class="grid grid-cols-3 lg:grid-cols-7 items-center mb-px p-2 text-sm tabular-nums hover:bg-slate-100 odd:bg-slate-50">
                    <div class="col-span-3 lg:col-span-2">
                        <p class="text-xs text-yellow-400" v-if="item.status == 1">Бронь</p>
                        <p class="text-xs text-red-400" v-if="[7, 8, 9].includes(item.status)">Подписка отменена</p>
                        <p class="text-lg">{{ item.event.name }}</p>
                    </div>

                    <div class="col-span-3 lg:col-span-1 flex">
                        <p v-if="item.type == 1">Однодневная</p>
                        <p v-if="item.type == 2">Недельная</p>
                        <p v-if="item.type == 3">Месячная</p>
                        <p v-if="item.type == 4">Квартальная</p>
                        <p v-if="item.type == 5">Годовая</p>
                    </div>

                    <p class="col-span-3 lg:col-span-1 lg:text-right text-lg font-bold">{{ item.price }} ₽</p>

                    <p class="col-span-3 lg:col-span-1 lg:text-right">{{ moment(item.create_at).format("DD.MM.YYYY") }}</p>

                    <div>
                        <p v-if="item.stop_at" class="col-span-3 lg:col-span-1 lg:text-right">{{ moment(item.stop_at).format("DD.MM.YYYY") }}</p>
                    </div>

                    <div class="col-span-3 lg:col-span-1 flex lg:justify-end items-center">
                        <div
                            class="circle mr-4"
                            :class="{
                                '!bg-yellow-400 ': item.status == 1,
                                '!bg-red-400 ': [7, 8, 9].includes(item.status),
                                '!bg-green-400 ': item.status == 5,
                            }"
                        ></div>
                        <div class="flex cursor-pointer hover:text-red-500" @click="(popup = 'cancel'), (currentSub = item)">
                            <div v-if="item.status < 7" class="flex justify-end mr-1">
                                <IconClose />
                            </div>
                            <span v-if="item.status == 5">Отменить</span>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>

    <template v-if="popup == 'cancel'">
        <div class="w-2/3 h-fit fixed left-1/2 -translate-x-1/2 top-20 max-h-[calc(100vh-112px)] overflow-auto rounded z-50 bg-white shadow-lg">
            <h3 class="text-center text-xl py-4 bg-black text-white">ОТМЕНИТЬ ПОДПИСКУ</h3>

            <div class="flex flex-col p-10 text-center">
                <p>Вы точно хотите отменить подписку?</p>

                <p class="text-lg mt-8">{{ currentSub.event.name }}</p>

                <p class="text-lg font-bold">{{ currentSub.price }} ₽</p>

                <p>{{ moment(currentSub.create_at).format("DD.MM.YYYY") }}</p>

                <div class="flex justify-center mt-8">
                    <div class="btn btn-red py-2 px-6" @click="cancelSub(currentSub), (popup = '')">Отменить подписку</div>
                </div>
            </div>
        </div>
        <div @click="popup = ''" class="fixed bg-slate-900 z-10 w-screen h-screen top-0 left-0 opacity-80"></div>
    </template>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";

export default {
    name: "SubscribesPage",

    data() {
        return {
            currentSub: {},
            popup: "",
            moment: moment,
        };
    },

    computed: {
        ...mapGetters(["s", "subscribes"]),
    },

    methods: {
        ...mapActions(["setPopup", "closePopup", "setSettings", "clearSettings", "getSubscribes", "findSub", "fillSub", "cancelSub"]),

        filterSub(status) {
            this.s.status = status;
            this.setSettings(this.s);
            this.findSub(this.s);
        },

        clear() {
            this.clearSettings();
            this.fillSub();
        },
    },

    mounted() {
        this.getSubscribes();
    },
};
</script>
<style>
.wrapper {
    display: grid;
    grid-template-columns: 1fr 300px;
}
</style>
