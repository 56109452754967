import axios from "axios";
import router from "@/services/router";

export default {
    state: {
        menu: [],
        profile: [],
    },

    mutations: {
        setMenu: (state, menu) => {
            state.menu = menu;
        },
        setProfile: (state, profile) => {
            localStorage.setItem("profile", JSON.stringify(profile));
            state.profile = profile;
        },
        setAccount: (state, account) => {
            localStorage.setItem("account", account);
            state.account = account;
        },
        setCustomers: (state, customers) => {
            state.customers = customers;
        },
    },

    actions: {
        login(ctx, data) {
            console.log("Login");
            let formData = new FormData();
            formData.append("username", data.username);
            formData.append("password", data.password);
            axios.post("https://pay.igoevent.com/site/login", formData).then((res) => {
                console.log(res.data);
                if (res.data && res.data.token) {
                    localStorage.setItem("AUTH", res.data.token);
                    ctx.commit("setProfile", res.data.profile);
                    router.push("/subscribes");
                }
            });
        },

        registration(ctx, data) {
            let formData = new FormData();
            formData.append("username", data.username);
            formData.append("email", data.email);
            formData.append("password", data.password);
            axios.post("https://pay.igoevent.com/site/registration", formData).then((res) => {
                console.log(res.data);
                if (res.data && res.data.token) {
                    localStorage.setItem("AUTH", res.data.token);
                    ctx.commit("setProfile", res.data.profile);
                    router.push("/subscribes");
                }
            });
        },

        sendCode(ctx, email) {
            let formData = new FormData();
            formData.append("email", email);
            axios.post("https://pay.igoevent.com/site/send-code", formData);
        },

        checkCode(ctx, data) {
            let formData = new FormData();
            formData.append("code", data.code);
            formData.append("username", data.email);
            formData.append("email", data.email);
            formData.append("password", data.password);
            axios.post("https://pay.igoevent.com/site/check-code", formData).then((res) => {
                if (res.data && res.data.token) {
                    localStorage.setItem("AUTH", res.data.token);
                    ctx.commit("setProfile", res.data.profile);
                    router.push("/subscribes");
                }
            });
        },

        logout(ctx) {
            localStorage.removeItem("AUTH");
            localStorage.removeItem("profile");
            ctx.commit("setProfile", null);
            ctx.commit("menu", null);
            router.push({ name: "login" });
        },

        getProfile(ctx) {
            let profile = localStorage.getItem("profile");
            if (profile) {
                ctx.commit("setProfile", JSON.parse(profile));
            } else {
                ctx.commit("setProfile", null);
            }
        },
    },

    getters: {
        menu(state) {
            return state.menu;
        },
        profile(state) {
            return state.profile;
        },
        customers(state) {
            return state.customers;
        },
    },
};
