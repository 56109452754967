<template>
    <div class="w-full lg:w-96 mt-10 m-auto rounded p-10 bg-white">
        <div v-if="form == 'login'" class="flex justify-between mb-4">
            <h3 class="w-full text-center text-xl text-black">Вход</h3>
            <!-- <h3 class="text-xl cursor-pointer text-slate-300" @click="form = 'registration'">Регистрация</h3> -->
        </div>
        <div v-if="form == 'registration'" class="flex justify-between mb-4">
            <h3 class="text-xl cursor-pointer text-slate-300" @click="form = 'login'">Вход</h3>
            <h3 class="text-xl text-yellow-500">Регистрация</h3>
        </div>
        <div class="flex flex-col">
            <div v-if="errors" class="errors">
                <p v-for="(error, field) in errors" :key="field">
                    {{ error[0] }}
                </p>
            </div>
            <form>
                <input
                    type="text"
                    class="w-full block mb-4 py-2 px-3 ring-1 ring-black text-slate-500 rounded"
                    v-model="username"
                    placeholder="Username"
                />
                <input
                    v-if="form == 'registration'"
                    type="email"
                    class="w-full block mb-4 py-2 px-3 ring-1 ring-black text-slate-500 rounded"
                    v-model="email"
                    placeholder="Email"
                />
                <input
                    v-if="form == 'login'"
                    type="password"
                    autocomplete="on"
                    class="w-full block mb-4 py-2 px-3 ring-1 ring-black text-slate-500 rounded"
                    v-model="password"
                    placeholder="Password"
                    @keyup.enter="login({ username: username, password: password })"
                />
                <input
                    v-if="form == 'registration'"
                    type="password"
                    autocomplete="on"
                    class="w-full block mb-4 py-2 px-3 ring-1 ring-black text-slate-500 rounded"
                    v-model="password"
                    placeholder="Password"
                    @keyup.enter="registration({ username: username, email: email, password: password })"
                />
            </form>
            <div v-if="form == 'login'" class="btn py-3 bg-black hover:bg-black/80" @click="login({ username: username, password: password })">
                Войти
            </div>
            <div
                v-if="form == 'registration'"
                class="btn btn-yellow py-3"
                @click="registration({ username: username, email: email, password: password })"
            >
                Зарегистрироваться
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
    name: "LoginPage",
    data() {
        return {
            form: "login",
            username: "",
            email: "",
            password: "",
            errors: null,
        };
    },

    methods: {
        ...mapActions(["login", "registration"]),
    },

    mounted() {},
};
</script>
