<template>
    <div class="w-full">
        <div class="bg-white p-4">
            <div class="flex flex-col lg:flex-row gap-4 justify-between items-center">
                <h2 class="text-sm text-slate-500">
                    ОПЛАТЫ <sup>{{ payments.length }}</sup>
                </h2>

                <!-- <p class="text-2xl text-slate-500 font-bold">{{ sum.toLocaleString() }} ₽</p> -->

                <!-- <input
                    type="text"
                    class="h-8 w-1/3 px-3 py-2 text-sm bg-slate-100"
                    placeholder="Название или ФИО гостя, email, телефон"
                    v-model="s.findme"
                    @input="findPayment(s)"
                /> -->

                <div class="flex w-full lg:w-auto justify-between gap-2">
                    <div
                        @click="filterPayment(1)"
                        class="w-auto lg:w-24 text-yellow-400 border border-yellow-400 hover:bg-yellow-300 hover:text-white text-xs lg:text-sm text-center p-1 rounded cursor-pointer"
                        :class="{ '!bg-yellow-300 !text-white': s.status == 1 }"
                    >
                        Бронь
                    </div>
                    <div
                        @click="filterPayment(5)"
                        class="w-auto lg:w-24 text-green-400 border border-green-400 hover:bg-green-300 hover:text-white text-xs lg:text-sm text-center p-1 rounded cursor-pointer"
                        :class="{ '!bg-green-300 !text-white': s.status == 5 }"
                    >
                        Оплачен
                    </div>
                    <div
                        @click="filterPayment(7)"
                        class="w-auto lg:w-24 text-red-400 border border-red-400 hover:bg-red-300 hover:text-white text-xs lg:text-sm text-center p-1 rounded cursor-pointer"
                        :class="{ '!bg-red-300 !text-white': s.status == 7 }"
                    >
                        Не&nbsp;оплачен
                    </div>
                    <div
                        @click="filterCanceled(1)"
                        class="w-auto lg:w-24 text-black border border-black hover:bg-black/80 hover:text-white text-xs lg:text-sm text-center p-1 rounded cursor-pointer"
                        :class="{ '!bg-black !text-white': s.cancel == 1 }"
                    >
                        Возврат
                    </div>

                    <div class="w-auto lg:w-20">
                        <div
                            v-if="s.clear"
                            @click="clear()"
                            class="w-auto lg:w-20 text-white bg-gray-400 hover:bg-gray-300 text-xs lg:text-sm text-center p-1 rounded cursor-pointer"
                        >
                            Сброс
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="bg-white p-4 mt-4">
            <div class="hidden lg:grid grid-cols-7 mb-4 px-2 text-xs text-slate-400">
                <p>#</p>
                <p class="col-span-2">Название</p>
                <p class="text-center">Оплачена</p>
                <p class="text-center">Тип</p>
                <p class="text-right">Стоимость</p>
                <p class="text-right">Статус/Возврат</p>
            </div>
            <template v-for="item in payments" :key="item.id">
                <div class="grid grid-cols-3 lg:grid-cols-7 items-center mb-px p-2 text-sm tabular-nums hover:bg-slate-100 odd:bg-slate-50">
                    <p>{{ item.id }}</p>

                    <div class="flex flex-col col-span-3 lg:col-span-2">
                        <p class="text-lg">{{ item.event.name }}</p>
                        <p class="text-xs">{{ item.order_id }}</p>
                    </div>

                    <div class="col-span-3 lg:col-span-1 lg:text-center">
                        <p>{{ moment(item.create_at).format("DD.MM.YYYY") }}</p>
                        <p>{{ moment(item.create_at).format("HH:mm:ss") }}</p>
                    </div>

                    <div class="col-span-3 lg:col-span-1 lg:text-center">
                        <p v-if="item.subscribe.type == 1">Дневная</p>
                        <p v-if="item.subscribe.type == 2">Недельная</p>
                        <p v-if="item.subscribe.type == 3">Месячная</p>
                        <p v-if="item.subscribe.type == 4">Квартальная</p>
                        <p v-if="item.subscribe.type == 5">Годовая</p>
                    </div>

                    <div class="col-span-3 lg:col-span-1 lg:text-right text-lg font-bold">{{ parseInt(item.sum).toLocaleString() }} ₽</div>
                    <div class="col-span-3 lg:col-span-1 flex lg:justify-end items-center">
                        <div
                            class="circle mr-2"
                            :class="{ '!bg-yellow-400 ': item.status == 1, '!bg-green-400 ': item.status == 5, '!bg-red-400 ': item.status == 7 }"
                        ></div>

                        <div class="circle mr-2" :class="{ '!bg-black ': item.canceled == 1 }"></div>
                        <!-- <div class="w-5">
                            <div
                                v-if="item.canceled < 1 && item.status == 5"
                                class="flex justify-end"
                                @click="(popup = 'cancel'), (currentPayment = item)"
                            >
                                <IconClose />
                            </div>
                        </div> -->
                    </div>
                </div>
            </template>
        </div>
    </div>

    <template v-if="popup == 'cancel'">
        <div class="w-2/3 h-fit fixed left-1/2 -translate-x-1/2 top-20 max-h-[calc(100vh-112px)] overflow-auto rounded z-50 bg-white shadow-lg">
            <h3 class="text-center text-xl py-4 bg-black text-white">ВЕРНУТЬ ОПЛАТУ</h3>

            <div class="flex flex-col p-10 text-center">
                <p>Вы точно хотите вернуть оплату?</p>

                <p class="text-lg mt-8">{{ currentPayment.event.name }}</p>

                <p class="text-lg">{{ currentPayment.profile.name }} {{ currentPayment.profile.secondname }}</p>
                <p>{{ currentPayment.profile.email }}</p>
                <p>{{ currentPayment.profile.phone }}</p>

                <p class="text-lg font-bold">{{ currentPayment.sum }} ₽</p>

                <p>{{ moment(currentPayment.create_at).format("DD.MM.YYYY") }}</p>

                <div class="flex justify-center mt-8">
                    <div class="btn btn-red py-2 px-6" @click="cancelPayment(currentPayment), (popup = '')">Вернуть оплату</div>
                </div>
            </div>
        </div>
        <div @click="popup = ''" class="fixed bg-slate-900 z-10 w-screen h-screen top-0 left-0 opacity-80"></div>
    </template>

    <template v-if="s.popup == 'viewPayment'"><ViewPayment /></template>
    <div v-if="s.popup" @click="closePopup()" class="fixed bg-slate-900 z-10 w-screen h-screen top-0 left-0 opacity-80"></div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import ViewPayment from "../components/Payment.vue";

export default {
    name: "PaymentsPage",

    components: { ViewPayment },

    data() {
        return {
            currentPayment: {},
            popup: "",
            moment: moment,
        };
    },

    computed: {
        ...mapGetters(["s", "payments"]),

        sum() {
            let sum = 0;
            sum = this.payments?.reduce((sum, item) => sum + item.sum, 0);
            return parseInt(sum);
        },
    },

    methods: {
        ...mapActions(["setPopup", "closePopup", "setSettings", "clearSettings", "getPayments", "findPayment", "fillPayment", "cancelPayment"]),

        filterPayment(status) {
            this.s.status = status;
            this.s.canceled = 0;
            this.setSettings(this.s);
            this.findPayment(this.s);
        },

        filterCanceled() {
            this.s.canceled = 1;
            this.setSettings(this.s);
            this.findPayment(this.s);
        },

        clear() {
            this.clearSettings();
            this.fillPayment();
        },
    },

    mounted() {
        this.getPayments();
    },
};
</script>
<style>
.wrapper {
    display: grid;
    grid-template-columns: 1fr 300px;
}
</style>
