import { createRouter, createWebHistory } from "vue-router";
import authService from "./auth";

import MainPage from "@/pages/Main";
import LoginPage from "@/pages/Login";
import ProfilePage from "@/pages/Profile";
import SubscribesPage from "@/pages/Subscribes";
import PaymentsPage from "@/pages/Payments";

const routes = [
    {
        path: "/",
        name: "home",
        component: MainPage,
    },
    {
        path: "/login",
        name: "login",
        component: LoginPage,
    },
    {
        path: "/profile",
        name: "profile",
        component: ProfilePage,
    },
    {
        path: "/subscribes",
        name: "subscribes",
        component: SubscribesPage,
    },
    {
        path: "/payments",
        name: "payments",
        component: PaymentsPage,
    },
];

const router = new createRouter({
    history: createWebHistory(),
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach(async (to) => {
    if (
        // make sure the user is authenticated
        !authService.isLogged() &&
        // Avoid an infinite redirect
        to.name !== "login" &&
        to.name !== "home"
    ) {
        // redirect the user to the login page
        return { name: "login" };
    }
});

export default router;
