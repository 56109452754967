<template>
    <div class="container mx-auto bg-white min-h-[calc(100vh-112px)] text-center flex flex-col justify-center">
        <div class="flex flex-col text-center">
            <span class="text-7xl font-black text-slate-500 leading-none">ID</span>
            <span class="text-slate-500">СЕРВИС ГОСТЯ</span>
            <h1 class="text-5xl font-bold">Я иду на событие!</h1>
            <div class="flex justify-center mt-8">
                <div class="flex flex-col justify-center items-center">
                    <div
                        class="flex justify-center bg-[#FFC838] hover:bg-[#FFD949] w-32 text-lg py-2 px-4 rounded cursor-pointer"
                        @click="$router.push('/login')"
                    >
                        ВОЙТИ
                    </div>
                    <div class="py-2 px-4 text-center rounded cursor-pointer" @click="popup = 'email'">Восстановить пароль</div>
                </div>
            </div>
        </div>
    </div>

    <div v-if="popup == 'email'" class="fixed left-1/2 -translate-x-1/2 top-20 w-96 max-h-[calc(100vh-112px)] bg-white z-50 shadow-sm">
        <h3 class="text-center text-xl py-4 bg-black text-white">ВОССТАНОВИТЬ ПАРОЛЬ</h3>
        <div class="p-10">
            <form>
                <input type="text" class="w-full block mb-4 py-2 px-3 ring-1 ring-black text-slate-500 rounded" v-model="email" placeholder="Email" />
            </form>
            <div class="btn py-3 text-black bg-[#FFC838] hover:bg-[#FFD949]" @click="sendCode(email), (popup = 'code')">Отправить</div>
        </div>
    </div>

    <div v-if="popup == 'code'" class="fixed left-1/2 -translate-x-1/2 top-20 w-96 max-h-[calc(100vh-112px)] bg-white z-50 shadow-sm">
        <h3 class="text-center text-xl py-4 bg-black text-white">ВВЕДИТЕ КОД</h3>
        <div class="p-10">
            <p class="text-center text-sm text-slate-500 mb-2">Введите код, отправленный вам на почту и задайте новый пароль</p>
            <form>
                <input type="text" class="w-full block mb-4 py-2 px-3 ring-1 ring-black text-slate-500 rounded" v-model="email" placeholder="Email" />

                <label class="text-left text-slate-400 text-xs mt-4">Код подтверждения</label>
                <input type="text" class="w-full block mb-4 py-2 px-3 ring-1 ring-black text-slate-500 rounded" v-model="code" placeholder="Код" />

                <label class="text-left text-slate-400 text-xs mt-4">Пароль</label>
                <input
                    type="text"
                    class="w-full block mb-4 py-2 px-3 ring-1 ring-black text-slate-500 rounded"
                    v-model="password"
                    placeholder="Пароль"
                />
            </form>
            <div
                class="btn py-3 text-black bg-[#FFC838] hover:bg-[#FFD949]"
                @click="checkCode({ email: email, code: code, password: password }), (popup = false)"
            >
                Войти
            </div>
        </div>
    </div>

    <div v-if="popup" @click="popup = ''" class="fixed bg-slate-900/50 z-10 w-screen h-screen top-0 left-0 opacity-80"></div>

    <!-- studiobaraban@gmail.com -->
</template>

<script>
import { mapActions } from "vuex";

export default {
    name: "BotHome",

    data() {
        return {
            popup: false,
            email: "",
            code: "",
            password: "",
        };
    },

    methods: {
        ...mapActions(["sendCode", "checkCode"]),
    },

    mounted() {},
};
</script>
